import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";

import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// ICONS
import HeaderLogo from "../img/litte_logo_orange.png";
import IG from "../img/IG_icon.png";
import DC from "../img/discord_icon.png";
import TW from "../img/twitter_icon.png";

import { Stack } from "@mui/system";
const drawerWidth = 240;

function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <a href="/">
          <img src={HeaderLogo} width="60%" alt="Otters Logo" />
        </a>
      </Typography>
      <Divider />
      <List>
        <Stack direction="column" alignItems={"center"} justifyContent="center">
          <Button
            sx={{
              fontSize: "18px",
              color: "var(--red)",
              fontFamily: "otter-sec",
            }}
          >
            <a
              href="#About"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              About
            </a>
          </Button>
          <Button
            sx={{
              fontSize: "18px",
              color: "var(--red)",
              fontFamily: "otter-sec",
            }}
          >
            <a
              href="#Teams"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Team
            </a>
          </Button>
          <Button
            sx={{
              fontSize: "18px",
              color: "var(--red)",
              fontFamily: "otter-sec",
            }}
          >
            <a
              href="#Roadmap"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Roadmap
            </a>
          </Button>
          <Stack direction="row" justifyContent={"space-around"} width="100%">
            <Box className="iconsTop">
              <a href="http://instagram.com/otters_universe">
                <img src={IG} alt="hh" width={15} />
              </a>
            </Box>
            <Box className="iconsTop">
              <a href="http://twitter.com/otters_universe">
                <img src={TW} alt="hh" width={15} />
              </a>
            </Box>
            <Box className="iconsTop">
              <a href="https://discord.gg/MPbB7EjBEW">
                <img src={DC} alt="hh" width={15} />
              </a>
            </Box>
          </Stack>
        </Stack>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        className="navbar-custom"
        elevation={0}
        sx={{
          paddingLeft: { xs: "", lg: "40px" },
          paddingRight: { xs: "", lg: "40px" },
        }}
      >
        <Toolbar>
          <IconButton
            // color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <a href="/">
              <img src={HeaderLogo} width="100px" alt="Otters Logo" />
            </a>
          </Typography>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Stack direction="row" alignItems={"center"}>
              <Button
                sx={{
                  fontSize: "18px",
                  color: "var(--red)",
                  fontFamily: "otter-sec",
                }}
              >
                <a
                  href="#About"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  About
                </a>
              </Button>
              <Button
                sx={{
                  fontSize: "18px",
                  color: "var(--red)",
                  fontFamily: "otter-sec",
                }}
              >
                <a
                  href="#Teams"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Team
                </a>
              </Button>
              <Button
                sx={{
                  fontSize: "18px",
                  color: "var(--red)",
                  fontFamily: "otter-sec",
                }}
              >
                <a
                  href="#Roadmap"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Roadmap
                </a>
              </Button>
              <Stack direction="row">
                <Box className="iconsTop">
                  <a href="http://instagram.com/otters_universe">
                    <img src={IG} alt="hh" width={15} />
                  </a>
                </Box>
                <Box className="iconsTop">
                  <a href="http://twitter.com/otters_universe">
                    <img src={TW} alt="hh" width={15} />
                  </a>
                </Box>
                <Box className="iconsTop">
                  <a href="https://discord.gg/MPbB7EjBEW">
                    <img src={DC} alt="hh" width={15} />
                  </a>
                </Box>
              </Stack>
            </Stack>

            {/* <Button sx={{ color: "var(--red)" }}>Hello</Button> */}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav" className="navbar-custom">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
