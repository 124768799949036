import { Box, Stack } from "@mui/material";
import React from "react";
// import teams from "../img/Teams.png";
import Twitter from "../img/t_icon.png";
import IG from "../img/Ig_icon_social.png";

import art01 from "../img/team/01.png";
import art02 from "../img/team/02.png";
import art03 from "../img/team/03.png";
import art04 from "../img/team/04.png";
import art05 from "../img/team/05.png";
import art06 from "../img/team/06.png";
// import art07 from "../img/team/07.png";

function Team() {
  return (
    <div>
      <div
        className="head-01"
        style={{ fontSize: "250%", margin: "40px", textAlign: "center" }}
      >
        Team
      </div>
      <Stack
        direction="row"
        flexWrap={"wrap"}
        alignItems="center"
        justifyContent={"center"}
        width={{ xs: "100%", lg: "100%" }}
      >
        {/* <img src={teams} width="100%" /> */}
        <Box width={{ xs: "220px", lg: "280px" }} margin="10px 10px">
          <img src={art01} width="100%" alt="teams" className="imger" />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-around"
            width="100%"
          >
            <Box>
              <a href="https://twitter.com/gokic_art">
                <img
                  src={Twitter}
                  alt="twitter"
                  width={30}
                  style={{ margin: "10px 20px" }}
                />
              </a>
              <a href="https://www.instagram.com/gokic_art/">
                <img
                  src={IG}
                  alt="twitter"
                  width={30}
                  style={{ margin: "10px 20px" }}
                />
              </a>
            </Box>
          </Stack>
        </Box>
        <Box width={{ xs: "220px", lg: "280px" }} margin="10px 10px">
          <img alt="teams" src={art02} width="100%" className="imger" />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-around"
            width={"90%"}
            style={{ visibility: "hidden" }}
          >
            <Box>
              <img
                src={Twitter}
                alt="twitter"
                width={30}
                style={{ margin: "10px 20px" }}
              />
              <img
                src={IG}
                alt="twitter"
                width={30}
                style={{ margin: "10px 20px" }}
              />
            </Box>
          </Stack>
        </Box>{" "}
        <Box width={{ xs: "220px", lg: "280px" }} margin="10px 10px">
          <img src={art03} width="100%" alt="teams" className="imger" />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-around"
            width={"90%"}
            style={{ visibility: "hidden" }}
          >
            <Box>
              <img
                src={Twitter}
                alt="twitter"
                width={30}
                style={{ margin: "10px 20px" }}
              />
              <img
                src={IG}
                alt="twitter"
                width={30}
                style={{ margin: "10px 20px" }}
              />
            </Box>
          </Stack>
        </Box>{" "}
        <Box width={{ xs: "220px", lg: "280px" }} margin="10px 10px">
          <img src={art04} width="100%" alt="teams" className="imger" />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-around"
            width={"100%"}
            // style={{ visibility: "hidden" }}
          >
            <Box>
              <a href="https://twitter.com/alter_horizons">
                <img
                  src={Twitter}
                  alt="twitter"
                  width={30}
                  style={{ margin: "10px 20px" }}
                />
              </a>
              <a href="https://www.instagram.com/alter_horizons/">
                <img
                  src={IG}
                  alt="twitter"
                  width={30}
                  style={{ margin: "10px 20px" }}
                />
              </a>
            </Box>
          </Stack>
        </Box>
        <Box width={{ xs: "220px", lg: "280px" }} margin="10px 10px">
          <img
            src={art05}
            alt="teams"
            width="100%"
            className="imger"
            // style={{ margin: "0px -10px 30px" }}
          />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-around"
            width={"90%"}
            style={{ visibility: "hidden" }}
          >
            <Box>
              <a href="https://twitter.com/alter_horizons">
                <img
                  src={Twitter}
                  alt="twitter"
                  width={30}
                  style={{ margin: "10px 20px" }}
                />
              </a>
              <a href="https://www.instagram.com/alter_horizons/">
                <img
                  src={IG}
                  alt="twitter"
                  width={30}
                  style={{ margin: "10px 20px" }}
                />
              </a>
            </Box>
          </Stack>
        </Box>
        <Box width={{ xs: "220px", lg: "280px" }} margin="10px 10px">
          <img
            src={art06}
            alt="teams"
            width="100%"
            className="imger"
            // style={{ margin: "0px -10px 30px" }}
          />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-around"
            width={"100%"}
            // style={{ visibility: "hidden" }}
          >
            <Box>
              <a href="https://twitter.com/alter_horizons">
                <img
                  src={Twitter}
                  alt="twitter"
                  width={30}
                  style={{ margin: "10px 20px" }}
                />
              </a>
              <a href="https://www.instagram.com/alter_horizons/">
                <img
                  src={IG}
                  alt="twitter"
                  width={30}
                  style={{ margin: "10px 20px" }}
                />
              </a>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </div>
  );
}

export default Team;
