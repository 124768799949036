import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

import MainLogo from "../img/main_logo.png";
import MintLogo from "../img/mint_bg.png";

function Main_01() {
  return (
    <div>
      <Stack alignItems={"center"} p={5}>
        <Box width={{ xs: "90%", lg: "60%" }}>
          <img src={MainLogo} width="100%" alt="main" />
        </Box>
        <Box
          onClick={() => (document.location.href = "/mint")}
          style={{
            position: "relative",
            height: "100px",
            margin: "20px",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              width: "350px",
              height: "90px",
            }}
            onClick={(e) => (document.location.href = "/mint")}
          >
            <img
              src={MintLogo}
              width="100%"
              height="100%"
              alt="main"
              style={{ marginTop: "40px", position: "relative" }}
              className="mint"
            />
          </Box>
          <a href="https://mint.ottersuniverse.com"><div className="head05">Click to Mint</div></a>
        </Box>
      </Stack>
    </div>
  );
}

export default Main_01;
