import { Box } from "@mui/material";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from "react-router-dom";

import Home from "./pages/Home";
import Mint from "./pages/Mint";

function App() {
  return (
    <div style={{ color: "var(--red)", maxWidth: "100vw" }}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mint" element={<Mint />} />
        </Routes>
      </Router>

      {/* <Home /> */}
      {/* <Mint /> */}
    </div>
  );
}

export default App;
