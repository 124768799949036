import { Box, Stack } from "@mui/material";
import React from "react";
import roadmap from "../img/roadmap.png";
import FollowTW from "../img/Follow_Twitter_bottom.png";
import IGFollow from "../img/IG_Follow_bottom.png";

function Roadmap() {
  return (
    <div>
      <div
        className="head-01"
        style={{ fontSize: "250%", margin: "40px", textAlign: "center" }}
      >
        Road Map
      </div>
      <Stack alignItems="center" justifyContent={"center"}>
        <Box width={{ xs: "100%", sm: "100%", lg: "80%" }}>
          <img src={roadmap} width="100%" />
        </Box>
      </Stack>
      <Stack direction={{ xs: "column", lg: "row" }}>
        <Stack justifyContent={"flex-end"} alignItems="center" flex="1">
          <Box sx={{ width: { xs: "60%", sm: "30%", md: "25%", lg: "25%" } }}>
            <img
              src={IGFollow}
              width="100%%"
              alt="main"
              style={{ marginTop: "40px" }}
              className="mint"
              onClick={() =>
                (document.location.href =
                  "https://instagram.com/otters_universe")
              }
            />
          </Box>
        </Stack>
        <Stack flex="1" alignItems="center">
          <Box sx={{ width: { xs: "60%", sm: "30%", md: "25%", lg: "25%" } }}>
            <img
              src={FollowTW}
              width="100%%"
              alt="main"
              style={{ marginTop: "40px" }}
              className="mint"
              onClick={() =>
                (document.location.href = "https://twitter.com/otters_universe")
              }
            />
          </Box>
        </Stack>
      </Stack>
    </div>
  );
}

export default Roadmap;
