import { Box } from "@mui/material";
import FAQ from "../components/FAQ";
import Header from "../components/Header";
import Introduction from "../components/Introduction";
import Kinds from "../components/Kinds";
import Main_01 from "../components/Main_01";
import Main_02 from "../components/Main_02";
// import Poper from "../components/Poper";
import Roadmap from "../components/Roadmap";
import Team from "../components/Team";
import Values from "../components/Values";

export default function Home() {
  return (
    <>
      <Header />
      <Main_01 />
      <Box id="About">
        <Introduction />
      </Box>
      <Main_02 />
      <Kinds />
      <Values />
      <Box id="Roadmap">
        <Roadmap />
      </Box>
      <Box id="Teams">
        <Team />
      </Box>
      <FAQ />
    </>
  );
}
