import mintcard from "../img/mintcard.png";
import remaining from "../img/rb.png";
import whitebox from "../img/white box.png";
import mintbtn from "../img/MINT_button.png";
import wlp from "../img/wlp.png";
import plus from "../img/plus.png";
import minus from "../img/minus.png";

import { Box, Button, Input, Stack } from "@mui/material";
import { useState } from "react";

export default function MintCard() {
  const [qty, setQty] = useState(0);

  const handleInc = () => {
    setQty((prev) => prev + 1);
  };
  const handleDec = () => {
    setQty((prev) => prev - 1);
  };

  return (
    <>
      <Box
        className="mintCard"
        width="430px"
        style={{ position: "relative", zIndex: "5" }}
      >
        <img src={mintcard} width="100%" height="100%" />
        <Box
          style={{
            position: "absolute",
            top: "120px",
            width: "100%",
          }}
        >
          <Stack alignItems={"center"} justifyContent="center">
            <Stack style={{ fontFamily: "otter", color: "white" }}>
              HELP / TWITTER
            </Stack>
            <Box mt={2} className="mintPageTitles">
              <Box>1. Connect your wallet</Box>
              <Box>2. Input the numbers of NFTs you wish to mint per txn.</Box>
              <Box>
                3. Click "Mint" and confirm the transaction that pops up in your
                wallet
              </Box>
            </Box>
            <Box>
              <Stack
                alignItems={"center"}
                mt={3}
                style={{ position: "relative" }}
              >
                <img src={remaining} width="95%" />
                <Box
                  style={{
                    position: "absolute",
                    top: "10px",
                    width: "90%",
                  }}
                >
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="space-around"
                    className="mintPageTitles"
                  >
                    <Stack alignItems="center">
                      <Box>Remaining</Box>
                      <Box>125</Box>
                    </Stack>
                    <Stack alignItems="center">
                      <Box>Price</Box>
                      <Box>0.1 ETH</Box>
                    </Stack>{" "}
                    <Stack alignItems="center" justifyContent={"center"}>
                      <Box></Box>
                      <Box>Live</Box>
                    </Stack>
                  </Stack>
                  <Stack
                    alignItems="center"
                    mt={1}
                    style={{ position: "relative" }}
                  >
                    <img src={whitebox} width="90%" />

                    <Box
                      style={{
                        position: "absolute",
                        top: "30%",
                        width: "90%",
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent={"space-around"}
                        height="100%"
                      >
                        {/* <Stack alignitems="center">
                                <img src={minus} width="30%" />
                              </Stack> */}
                        <Stack
                          direction="row"
                          alignitems="center"
                          justifyContent="space-around"
                          width="100%"
                          style={{
                            fontFamily: "otter",
                            color: "var(--main)",
                            fontSize: "160%",
                          }}
                        >
                          <Box
                            style={{ cursor: "pointer" }}
                            onClick={handleDec}
                          >
                            -
                          </Box>
                          {qty}
                          <Box
                            onClick={handleInc}
                            style={{ cursor: "pointer" }}
                          >
                            +
                          </Box>
                        </Stack>
                        {/* <Stack alignitems="center">
                                <img src={minus} width="30%" />
                              </Stack> */}
                      </Stack>
                    </Box>
                  </Stack>
                  <Stack alignItems="center" mt={1}>
                    <img src={mintbtn} width="70%" />
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>
          {/* <Stack alignItems="center" mt={4}>
            <img src={wlp} width="70%" />
          </Stack> */}
        </Box>
      </Box>
    </>
  );
}
