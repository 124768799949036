import { Box, Stack } from "@mui/material";
import React from "react";
import IGFollow from "../img/IG_Follow_bottom.png";

function Introduction() {
  return (
    <div>
      <Stack style={{ textAlign: "center" }} className="head-main">
        <div className="head-01">A FINE ART BRAND IN METAVERSE</div>
        <Box className="cont">
          <div className="head-02">
            We provide affordable yet professional Fine Art Collecting
            experience
          </div>
          <p className="para01">
            We are thrilled to announce Otters Universe: the first{" "}
            <b>[Fine Art Project]</b> art collectible NFT of its kind that will
            also grant holders official gift box, Creative License, and
            membership benefits to the Otters Universe Family.
          </p>
          <p className="para01">
            2373 Otters, selling at 0.1 ETH each, are meeting everyone{" "}
            <b>this FALL.</b> You are joining the destined few, who has the will
            to explore and discover among the universes. We are an explorer
            family. We explore, we discover, we create.{" "}
          </p>
          <p className="para01">
            This is an exciting and new way to position cannabis culture in the
            NFT space. When you join the Otters Universe gang you will mint
            awesome collectible one-of-one art, join an awesome community, and
            more!
          </p>
          <Stack alignItems={"center"}>
            <Box sx={{ width: { xs: "60%", md: "30%", sm: "30%", lg: "15%" } }}>
              <img
                src={IGFollow}
                width="100%%"
                alt="main"
                style={{ marginTop: "40px" }}
                className="mint"
                onClick={() =>
                  (document.location.href =
                    "https://instagram.com/otters_universe")
                }
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </div>
  );
}

export default Introduction;
