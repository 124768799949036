import { Box, Stack } from "@mui/material";
import React from "react";
import Ainstein from "../img/A_logo.png";
import Bizaar from "../img/B_logo.png";
import Young_Club from "../img/Y_club.png";

function FAQ() {
  return (
    <div style={{ height: "90vh" }}>
      {" "}
      <div
        className="head-01"
        style={{ fontSize: "250%", marginTop: "70px", textAlign: "center" }}
      >
        FAQ
      </div>
      <Stack
        height="100%"
        alignItems={"center"}
        justifyContent="center"
        className="head-01"
        style={{ fontSize: "180%", margin: "40px", textAlign: "center" }}
      >
        We will release information later
      </Stack>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          marginLeft: "5px",
        }}
      >
        <Stack direction="row" alignItems="center">
          <div
            style={{
              fontFamily: "otter-sec",
              fontSize: "14px",
            }}
          >
            MEDIA PARTNER
          </div>
          <Box height={{ xs: "220px", sm: "140px", lg: "150px" }}>
            <a href="/">
              <img src={Young_Club} height="100%" alt="YC" />
            </a>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center">
          <div
            style={{
              fontFamily: "otter-sec",
              fontSize: "14px",
            }}
          >
            BUSINESS PARTNER
          </div>
          <Stack direction="row" alignItems="center">
            <Box height={{ xs: "120px", sm: "140px", lg: "150px" }}>
              <a href="https://www.bizaar.biz/">
                <img src={Bizaar} height="100%" alt="BIZZ" />
              </a>
            </Box>
            <Box height={{ xs: "69px", sm: "90px", lg: "100px" }}>
              <a href="https://www.ainstein.com.hk/">
                <img src={Ainstein} height="100%" alt="ainstein" />
              </a>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <p style={{ fontFamily: "otter-sec", margin: "50px", fontSize: "15px" }}>
        © OTTERS UNIVERSE 2022. ALL RIGHTS RESERVED.
      </p>
    </div>
  );
}

export default FAQ;
