import { Box, Stack } from "@mui/material";
import React from "react";

import value01 from "../img/value01.png";
import value02 from "../img/value02.png";
import value03 from "../img/value03.png";

function Values() {
  return (
    <div>
      <div
        className="head-01"
        style={{ fontSize: "250%", marginTop: "70px", textAlign: "center" }}
      >
        Our Values
      </div>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        mt={6}
        alignItems="center"
        justifyContent={"center"}
        p={{ xs: 2, lg: 3 }}
      >
        <Stack flex="1">
          <div className="head04">Originality and Creativity</div>
          <p className="para03">
            We share authentic and original art pieces as NFT and encourage bold
            voices by collabrating with local emerging artists to make unique
            art and products !
          </p>
        </Stack>
        <Stack flex="1" alignItems={"center"}>
          <Box width="80%">
            <img src={value01} width="100%" />
          </Box>
        </Stack>
      </Stack>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        mt={1}
        alignItems="center"
        justifyContent={"center"}
        p={{ xs: 2, lg: 5 }}
      >
        <Stack flex="1">
          <div className="head04">Giving back to our supportors</div>
          <p className="para03">
            We merge art with business model and provide membership benefits to
            our NFT holders: Inclduding F&B, Shopping and Crypto Services
            discounts !
          </p>
        </Stack>
        <Stack flex="1" alignItems={"center"}>
          <Box width="80%">
            <img src={value02} width="100%" />
          </Box>
        </Stack>
      </Stack>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        mt={1}
        alignItems="center"
        justifyContent={"center"}
        p={{ xs: 2, lg: 5 }}
      >
        <Stack flex="1">
          <div className="head04">Invest in ART</div>
          <p className="para03">
            Art is collectibles. Get your art in trendy NFTs and support artist
            with us !
          </p>
        </Stack>
        <Stack flex="1" alignItems={"center"}>
          <Box width="80%">
            <img src={value03} width="100%" />
          </Box>
        </Stack>
      </Stack>
    </div>
  );
}

export default Values;
