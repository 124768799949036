import { Box, Button, Input, Stack } from "@mui/material";
import Confetti from "../components/Confetti";

import mintcard from "../img/mintcard.png";
import remaining from "../img/rb.png";
import whitebox from "../img/white box.png";
import mintbtn from "../img/MINT_button.png";
import wlp from "../img/wlp.png";
import plus from "../img/plus.png";
import minus from "../img/minus.png";
import IG from "../img/IG_icon.png";
import DC from "../img/discord_icon.png";
import TW from "../img/twitter_icon.png";

import MintCard from "../components/MintCard";

export default function Mint() {
  return (
    <>
      <Box className="mintMain">
        {/* <Box display={{lg:"block", sm:}}> */}

        {/* <Confetti /> */}
        {/* </Box> */}
        <Box variant="header">
          <Stack direction={"row"} mt={3} justifyContent="flex-end" width="95%">
            <Box className="address">
              <span
                style={{
                  color: "var(--red)",
                }}
              >
                Connected
              </span>{" "}
              | 0x4a21245...12sa35
            </Box>
          </Stack>
        </Box>
        <Stack
          direction={{ xs: "column", sm: "column", lg: "row" }}
          width="100%"
          height="90%"
        >
          <Stack
            flex={{ xs: "", lg: "1" }}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              onClick={() => (document.location.href = "/mint")}
              style={{
                top: "-40px",
                position: "relative",
                height: "30px",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  width: "350px",
                  height: "90px",
                }}
                onClick={(e) => (document.location.href = "/mint")}
              >
                <img
                  src={remaining}
                  width="100%"
                  height="70%"
                  alt="main"
                  style={{ position: "relative" }}
                  className="mint"
                />
              </Box>
              <div className="head05">Connect Wallet</div>
            </Box>
            <Box
              display={{ xs: "none", lg: "block" }}
              className="MintH"
              padding={4}
              style={{
                // background: "red",
                border: "1px solid #ccc",
                borderRadius: "20px",
                backdropFilter: "blur(4px)",
                marginLeft: "60px",
                zIndex: 5,
              }}
            >
              Join the Party
            </Box>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box className="iconsTop" mt={2}>
                <a href="http://instagram.com/otters_universe">
                  <img src={IG} alt="hh" width={20} />
                </a>
              </Box>
              <Box className="iconsTop" mt={2}>
                <a href="http://twitter.com/otters_universe">
                  <img src={TW} alt="hh" width={20} />
                </a>
              </Box>
              <Box className="iconsTop" mt={2}>
                <a href="https://discord.gg/MPbB7EjBEW">
                  <img src={DC} alt="hh" width={20} />
                </a>
              </Box>
            </Stack>
          </Stack>
          <Stack
            flex="1"
            alignItems="center"
            justifyContent={{ xs: "center", lg: "flex-end" }}
            direction="row"
            mr={{ xs: 0, lg: 5 }}
          >
            <MintCard />{" "}
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
