import { Box, Stack } from "@mui/material";
import React from "react";
import otter01 from "../img/otter01.png";
import FollowTW from "../img/Follow_Twitter_bottom.png";
import IGFollow from "../img/IG_Follow_bottom.png";

function Main_02() {
  return (
    <>
      <Stack direction={{ xs: "column", md: "row", lg: "row" }}>
        <Stack flex="1" alignItems={"center"} justifyContent="center">
          <Box sx={{ width: { xs: "60%", lg: "50%" } }}>
            <img src={otter01} width="100%" alt="otter01" />
          </Box>
        </Stack>
        <Box flex="1">
          <p className="para02">
            Otters are highly intelligent and yet cute-looking creatures. They
            was discovered by the Parallel Universe Traveller Yahe, named this
            universe the Otters universe.
          </p>

          <p className="para02">
            Space traveller accidentally discovered this universe during a
            random exploration mission. These cute-looking creatures live on
            different planets, Otters on each planet have different
            civilizations and races.
          </p>

          <p className="para02">
            He has made an illustrated book "the Otters Guide" to catalogue the
            Otters. Circulating in human’s universe. And this is the guide.
          </p>
        </Box>
      </Stack>
      <Stack alignItems={"center"}>
        <Box sx={{ width: { xs: "60%", md: "30%", sm: "30%", lg: "15%" } }}>
          <img
            src={FollowTW}
            width="100%%"
            alt="main"
            style={{ marginTop: "40px" }}
            className="mint"
            onClick={() =>
              (document.location.href = "https://twitter.com/otters_universe")
            }
          />
        </Box>
      </Stack>
    </>
  );
}

export default Main_02;
