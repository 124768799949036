import { Box, Fade, Popover } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import ot15 from "../img/otters/015.png";
import ot14 from "../img/otters/014.png";
import ot13 from "../img/otters/013.png";
import ot12 from "../img/otters/012.png";
import ot11 from "../img/otters/011.png";
import ot10 from "../img/otters/010.png";
import ot9 from "../img/otters/009.png";
import ot8 from "../img/otters/008.png";
import ot7 from "../img/otters/007.png";
import ot6 from "../img/otters/006.png";
import ot5 from "../img/otters/005.png";
import ot4 from "../img/otters/004.png";
import ot3 from "../img/otters/003.png";
import ot2 from "../img/otters/002.png";
import ot1 from "../img/otters/001.png";

import kR from "../img/BG_ott.png";
import msg from "../img/otter_msg.png";

function Kinds() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const [anchorEl6, setAnchorEl6] = React.useState(null);
  const [anchorEl7, setAnchorEl7] = React.useState(null);
  const [anchorEl8, setAnchorEl8] = React.useState(null);
  const [anchorEl9, setAnchorEl9] = React.useState(null);
  const [anchorEl10, setAnchorEl10] = React.useState(null);
  const [anchorEl11, setAnchorEl11] = React.useState(null);
  const [anchorEl12, setAnchorEl12] = React.useState(null);
  const [anchorEl13, setAnchorEl13] = React.useState(null);
  const [anchorEl14, setAnchorEl14] = React.useState(null);
  const [anchorEl15, setAnchorEl15] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };
  const handleClick3 = (event) => {
    setAnchorEl3(anchorEl3 ? null : event.currentTarget);
  };
  const handleClick4 = (event) => {
    setAnchorEl4(anchorEl4 ? null : event.currentTarget);
  };
  const handleClick5 = (event) => {
    setAnchorEl5(anchorEl5 ? null : event.currentTarget);
  };
  const handleClick6 = (event) => {
    setAnchorEl6(anchorEl6 ? null : event.currentTarget);
  };
  const handleClick7 = (event) => {
    setAnchorEl7(anchorEl7 ? null : event.currentTarget);
  };
  const handleClick8 = (event) => {
    setAnchorEl8(anchorEl8 ? null : event.currentTarget);
  };
  const handleClick9 = (event) => {
    setAnchorEl9(anchorEl9 ? null : event.currentTarget);
  };
  const handleClick10 = (event) => {
    setAnchorEl10(anchorEl10 ? null : event.currentTarget);
  };
  const handleClick11 = (event) => {
    setAnchorEl11(anchorEl11 ? null : event.currentTarget);
  };
  const handleClick12 = (event) => {
    setAnchorEl12(anchorEl12 ? null : event.currentTarget);
  };
  const handleClick13 = (event) => {
    setAnchorEl13(anchorEl13 ? null : event.currentTarget);
  };
  const handleClick14 = (event) => {
    setAnchorEl14(anchorEl14 ? null : event.currentTarget);
  };
  const handleClick15 = (event) => {
    setAnchorEl15(anchorEl15 ? null : event.currentTarget);
  };

  // const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);
  const open5 = Boolean(anchorEl5);
  const open6 = Boolean(anchorEl6);
  const open7 = Boolean(anchorEl7);
  const open8 = Boolean(anchorEl8);
  const open9 = Boolean(anchorEl9);
  const open10 = Boolean(anchorEl10);
  const open11 = Boolean(anchorEl11);
  const open12 = Boolean(anchorEl12);
  const open13 = Boolean(anchorEl13);
  const open14 = Boolean(anchorEl14);
  const open15 = Boolean(anchorEl15);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Stack direction={{ xs: "column", lg: "row" }}>
      <Stack
        sx={{ mt: { xs: "20px", lg: "" } }}
        flex="1"
        alignItems={"center"}
        justifyContent="center"
        direction="row"
        flexWrap={"wrap"}
        style={{ position: "relative" }}
      >
        <Box width="220px">
          <img
            src={ot15}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick}
            // onMouseOut={() => console.log(setAnchorEl((open = false)))}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            placement="top-start"
            // transition
          >
            {/* {({ TransitionProps }) => (
              <Fade
                {...TransitionProps}
                timeout={350}
                style={{ position: "absolute", top: "100px" }}
              > */}
            <Box>
              <Box className="popImg">
                <img src={msg} alt="msg" height={180} />
              </Box>
              <Box className="popMsg">
                “The Friendly Ghosts” – Ghost Otters lives a peaceful life alone
                and away from the rest of the Otters, they enjoy their life
                quiet and delightful, neutral to all affairs outside their
                Valley.
              </Box>
            </Box>
            {/* </Fade>
            )} */}
          </Popover>
        </Box>

        <Box width="220px">
          <img
            src={ot14}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick2}
            // onMouseLeave={handleClick2}
          />
          <Popover
            id={id}
            open={open2}
            anchorEl={anchorEl2}
            placement="top-start"
            transition
            onClose={() => setAnchorEl2(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                Living among the forest, Rabbit Otters are very social and can’t
                live off carrots!
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box width="220px">
          <img
            src={ot13}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick3}
          />
          <Popover
            id={id}
            open={open3}
            anchorEl={anchorEl3}
            placement="top-start"
            transition
            onClose={() => setAnchorEl3(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                Living in the remote land on the Continent, the Primitive Otters
                are the legacy of the ancient universe, a living history.
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box width="220px">
          <img
            src={ot12}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick4}
          />
          <Popover
            id={id}
            open={open4}
            anchorEl={anchorEl4}
            placement="top-start"
            onClose={() => setAnchorEl4(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                Egyptian Otters are the most civilized and intelligent Otters
                across the continent. They remain powerful and rich by their
                ancient yet rich knowledge and advanced technology.
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box width="220px">
          <img
            src={ot11}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick5}
          />
          <Popover
            id={id}
            open={open5}
            anchorEl={anchorEl5}
            placement="top-start"
            onClose={() => setAnchorEl5(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                Meet the adorable Meow Otters! These speedy Meows are even loved
                by the ancient Egyptian Otters, and now they reside joyfully in
                the fairy Fantasy Land.
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box width="220px">
          <img
            src={ot10}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick6}
          />
          <Popover
            id={id}
            open={open6}
            anchorEl={anchorEl6}
            placement="top-start"
            onClose={() => setAnchorEl6(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                Otters in their prettiest, original marine form! Life under the
                water is as joyful as it is on land – Ocean Otters living their
                best social life here!
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box width="220px">
          <img
            src={ot9}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick7}
          />
          <Popover
            id={id}
            open={open7}
            anchorEl={anchorEl7}
            placement="top-start"
            onClose={() => setAnchorEl7(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                Warrior Otters are the loyal guardians protecting both the
                Egyptian and Kingdom royalties. It is their honorable duty to
                defeat their kingdoms and safeguard their lords.
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box width="220px">
          <img
            src={ot8}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick8}
          />
          <Popover
            id={id}
            open={open8}
            anchorEl={anchorEl8}
            placement="top-start"
            onClose={() => setAnchorEl8(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                Citizens, cute, chill, happy-ever-after community living in the
                capital city within the Otters Kingdom, ruled by the Kingdom
                Otters. Most common within the Otter Universe.
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box width="220px">
          <img
            src={ot7}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick9}
          />
          <Popover
            id={id}
            open={open9}
            anchorEl={anchorEl9}
            placement="top-start"
            onClose={() => setAnchorEl9(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                Witty, clever, and magical – the Magic Otters are the master of
                sorcery and make the greatest wonders in the Otters history. But
                be careful, some of them could be very mischief.
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box width="220px">
          <img
            src={ot6}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick10}
          />
          <Popover
            id={id}
            open={open10}
            anchorEl={anchorEl10}
            placement="top-start"
            onClose={() => setAnchorEl10(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                Glamours, classy, noble – Kingdom Otters are the stars, the key
                figures on the whole continent.
              </Box>
            </Box>
          </Popover>
        </Box>

        <Box width="220px">
          <img
            src={ot5}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick11}
          />
          <Popover
            id={id}
            open={open11}
            anchorEl={anchorEl11}
            placement="top-start"
            onClose={() => setAnchorEl11(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                Holy creatures from ancient Universe, the Dragon Otters are
                powerful, and can live 1,100 years – 11 times more than a
                regular Otter. The Dragons are also believed and praised as the
                gods and spirit guardians of the Kingdom Otters, loved, and
                honored by its royalties.
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box width="220px">
          <img
            src={ot4}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick12}
          />
          <Popover
            id={id}
            open={open12}
            anchorEl={anchorEl12}
            placement="top-start"
            onClose={() => setAnchorEl12(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                They are SWEET as honeycomb! The Dessert Otters are the master
                of cakes, pastries, and doughnuts. They know how to live the
                sweetest happiest life!
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box width="220px">
          <img
            src={ot3}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick13}
          />
          <Popover
            id={id}
            open={open13}
            anchorEl={anchorEl13}
            placement="top-start"
            onClose={() => setAnchorEl13(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                Mystery and charming creature from the outer space! Rumor says
                they came from a parallel Otters Universe.
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box width="220px">
          <img
            src={ot2}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick14}
          />
          <Popover
            id={id}
            open={open14}
            anchorEl={anchorEl14}
            placement="top-start"
            onClose={() => setAnchorEl14(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                Mystery and charming creature from the outer space! Rumor says
                they came from a parallel Otters Universe.
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box width="220px">
          <img
            src={ot1}
            height="100%"
            width="100%"
            className="imger"
            onClick={handleClick15}
          />
          <Popover
            id={id}
            open={open15}
            anchorEl={anchorEl15}
            placement="top-start"
            onClose={() => setAnchorEl15(null)}
          >
            <Box sx={{}}>
              <Box className="popImg">
                <img src={msg} alt="msg" width={280} />
              </Box>
              <Box className="popMsg">
                One and only, superior, and unique otters – God like figure of
                each species. They are extremely rare and live in the shadow
                within the universe.
              </Box>
            </Box>
          </Popover>
        </Box>
      </Stack>
      <Stack
        flex="1"
        alignItems={"center"}
        justifyContent="space-between"
        sx={{ mt: { xs: "20px", lg: "" } }}
      >
        <Stack alignItems={"center"}>
          <Box width="60%">
            <img src={kR} height="100%" width="100%" />
          </Box>
          <p className="para02" style={{ width: "90%" }}>
            <div className="head03">
              CLICK ON THE OTTERS TO CHECK OUT THEIR INFO !
            </div>
          </p>
        </Stack>
        <Stack
          justifyContent={"center"}
          alignItems="center"
          sx={{ width: { xs: "80%", lg: "40%" } }}
        >
          {/* <div className="head03">
            CLICK ON THE OTTERS TO CHECK OUT THEIR INFO !
          </div> */}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Kinds;
